






































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  ITicket,
  ITicketSubType,
  ITicketType,
  ITicketUpdate,
} from "@/interfaces";
import {
  dispatchGetOneTicket,
  dispatchGetTicketTypes,
  dispatchUpdateTicket,
} from "@/store/admin/actions";
import { apiUrl } from "@/env";
import { readCityId } from "@/store/main/getters";

@Component
export default class EditUser extends Vue {
  private ticketState?: ITicket;
  private hasTicketState = false;

  private isActive = true;
  private title: string = "";
  private description: string = "";
  private cityFeedback: string = "";
  private locationText: string = "";
  private reporterName: string = "";
  private reporterEmail: string = "";
  private ticketType: number = 0;
  private ticketSubType: number = 0;
  private newImage?: File;

  private ticketTypes: ITicketType[] = [];
  private ticketSubTypes: ITicketSubType[] = [];

  public valid = true;

  @Watch("ticketType")
  public updateTicketSubTypes(value: number) {
    this.ticketSubTypes = this.ticketTypes.filter(
      (ticketType) => ticketType.id == value
    )[0].sub_types;
    this.ticketSubType = this.ticketSubTypes[0].id ?? 0;
  }

  public selectedImage(file: File) {
    this.newImage = file;
  }

  get apiUrl() {
    return apiUrl;
  }

  get center() {
    if (this.ticketState?.latitude && this.ticketState?.longitude) {
      return {
        lat: this.ticketState.latitude,
        lng: this.ticketState.longitude,
      };
    }
    return null;
  }

  public async mounted() {
    this.ticketState = await dispatchGetOneTicket(this.$store, {
      cityId: readCityId(this.$store),
      ticketId: +this.$router.currentRoute.params.id,
    });
    this.ticketTypes = await dispatchGetTicketTypes(
      this.$store,
      readCityId(this.$store)
    );
    this.hasTicketState = true;
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.ticketState) {
      this.isActive = this.ticketState.is_active;
      this.title = this.ticketState.title;
      this.description = this.ticketState.description ?? "";
      this.cityFeedback = this.ticketState.city_feedback ?? "";
      this.locationText = this.ticketState.location_text ?? "";
      this.reporterName = this.ticketState.reporter_name ?? "";
      this.reporterEmail = this.ticketState.reporter_email ?? "";
      this.ticketType = this.ticketState.ticket_sub_type.owner_type.id;
      this.ticketSubType = this.ticketState.ticket_sub_type.id;
      this.newImage = undefined;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedTicket: ITicketUpdate = {
        is_active: this.isActive,
        title: this.title,
        description: this.description,
        city_feedback: this.cityFeedback,
        location_text: this.locationText,
        reporter_name: this.reporterName,
        reporter_email: this.reporterEmail,
        file: this.newImage,
      };
      await dispatchUpdateTicket(this.$store, {
        cityId: readCityId(this.$store),
        ticketId: +this.$router.currentRoute.params.id,
        ticket: updatedTicket,
      });
      this.$router.push("/main/city/tickets");
    }
  }
}
